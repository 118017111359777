// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase';
import { withRouter } from 'react-router-dom';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import AutomaticCheckoutCalculation2 from "../../blocks/AutomaticCheckoutCalculation2/src/AutomaticCheckoutCalculation2";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import AdvancedSearch from "../../blocks/advancedsearch/src/AdvancedSearch";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import Payments from "../../blocks/Payments/src/Payments";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import HelpCentre from "../../blocks/helpcentre/src/HelpCentre";
import HelpCentreQA from "../../blocks/helpcentre/src/HelpCentreQA";
import HelpCentreSub from "../../blocks/helpcentre/src/HelpCentreSub";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Customform from "../../blocks/customform/src/Customform";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import CfFacebookLinkage from "../../blocks/CfFacebookLinkage/src/CfFacebookLinkage";
import Notifications from "../../blocks/notifications/src/Notifications";
import CfInstagramLinkage from "../../blocks/CfInstagramLinkage/src/CfInstagramLinkage";
import Analytics from "../../blocks/analytics/src/Analytics";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import PdfEdit from "../../blocks/pdfedit/src/PdfEdit";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import DashboardGuests from "../../blocks/DashboardGuests/src/DashboardGuests";
import MultilevelApproval from "../../blocks/MultilevelApproval/src/MultilevelApproval";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import UserProfileBasicBlock from "../../blocks/user-profile-basic/src/UserProfileBasicBlock";
import SentimentAnalysis from "../../blocks/SentimentAnalysis/src/SentimentAnalysis";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import ContentManagement from "../../blocks/ContentManagement/src/ContentManagement";
import EngagementCalculator from "../../blocks/EngagementCalculator/src/EngagementCalculator";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import CfTwitterLinkage from "../../blocks/CfTwitterLinkage/src/CfTwitterLinkage";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Sorting from "../../blocks/sorting/src/Sorting";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Contactus from "../../blocks/contactus/src/ContactUsScreen.web";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import EducationalUserProfile from "../../blocks/educational-user-profile/src/EducationalUserProfile";
import Notificationsettings from "../../blocks/notificationsettings/src/Notificationsettings";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Dashboard from "../../blocks/dashboard/src/Dashboard";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import CfYoutubeLinkage from "../../blocks/CfYoutubeLinkage/src/CfYoutubeLinkage";
import LandingPage from "../../blocks/landingpage/src/LandingPage";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import PaymentAdmin from "../../blocks/PaymentAdmin/src/PaymentAdmin";
import StripeIntegration from "../../blocks/stripepayments/src/StripeIntegration";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import RolesPermissions from "../../blocks/RolesPermissions/src/RolesPermissions";
import AdminConsole from "../../blocks/AdminConsole/src/AdminConsole";
import EmailNotifications2 from "../../blocks/EmailNotifications2/src/EmailNotifications2";
// Customizable Area Start
import MobileAccountRegistration from "../../blocks/mobile-account-registration/src/MobileAccountRegistration.web";
import VerifyMobileRegistration from "../../blocks/mobile-account-registration/src/VerifyMobileRegistration.web";
import MobileAccountLogin from "../../blocks/mobile-account-login/src/MobileAccountLogin.web";
import VerifyMobileLogin from "../../blocks/mobile-account-login/src/VerifyMobileLogin.web";
import HomepageWeb from "../../blocks/landingpage/src/Homepage.web";
import AccountSettings from "../../blocks/Settings5/src/AccountSettings.web";
import HelpAndFaq from "../../blocks/helpcentre/src/HelpAndFaq.web";
// Customizable Area End



const routeMap = {
  // Customizable Area Start
  MobileAccountLogin: {
    component: MobileAccountLogin,
    path: "/",
    exact: true
  },
  VerifyMobileLogin: {
    component: VerifyMobileLogin,
    path: "/verify_login",
    exact: true
  },
  MobileAccountRegistration: {
    component: MobileAccountRegistration,
    path: "/signup",
    exact: true
  },
  VerifyMobileRegistration: {
    component: VerifyMobileRegistration,
    path: "/verify_signup",
    exact: true
  },
  HomepageWeb: {
    component: HomepageWeb,
    path: "/Home",
    exact: true
  },
  HelpAndFaq: {
    component: HelpAndFaq,
    path: "/helpAndFaq",
    exact: true
  },
  // Customizable Area End
  PaymentAdmin: {
    component: PaymentAdmin,
    path: "/PaymentAdmin"
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: "/StripeIntegration"
  },
  Customisableusersubscriptions: {
    component: Customisableusersubscriptions,
    path: "/Customisableusersubscriptions"
  },
  SubscriptionDetails: {
    component: SubscriptionDetails,
    path: "/SubscriptionDetails"
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: "/RolesPermissions"
  },
  AdminConsole: {
    component: AdminConsole,
    path: "/AdminConsole"
  },
  EmailNotifications2: {
    component: EmailNotifications2,
    path: "/EmailNotifications2"
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: "/VisualAnalytics"
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: "/CustomisableUserProfiles"
  },
  AccountSettings: {
    component: AccountSettings,
    path: "/settings",
    exact: true
  },
  AutomaticCheckoutCalculation2: {
    component: AutomaticCheckoutCalculation2,
    path: "/AutomaticCheckoutCalculation2"
  },
  Ordermanagement: {
    component: Ordermanagement,
    path: "/Ordermanagement"
  },
  OrderDetails: {
    component: OrderDetails,
    path: "/OrderDetails"
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: "/SocialMediaAccountLoginScreen"
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLoginBlock"
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: "/ShoppingCartOrders"
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: "/AddShoppingCartOrderItem"
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: "/AdvancedSearch"
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: "/RolesPermissions2"
  },
  AccountScoreranking: {
    component: AccountScoreranking,
    path: "/AccountScoreranking"
  },
  Payments: {
    component: Payments,
    path: "/Payments"
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: "/InvoiceBilling"
  },
  PeopleManagement2: {
    component: PeopleManagement2,
    path: "/PeopleManagement2"
  },
  HelpCentre: {
    component: HelpCentre,
    path: "/HelpCentre"
  },
  HelpCentreQA: {
    component: HelpCentreQA,
    path: "/HelpCentreQA"
  },
  HelpCentreSub: {
    component: HelpCentreSub,
    path: "/HelpCentreSub"
  },
  PaymentAdmin2: {
    component: PaymentAdmin2,
    path: "/PaymentAdmin2"
  },
  Customform: {
    component: Customform,
    path: "/Customform"
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: "/Pushnotifications"
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: "/ForgotPassword"
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: "/ForgotPasswordOTP"
  },
  NewPassword: {
    component: NewPassword,
    path: "/NewPassword"
  },
  CfFacebookLinkage: {
    component: CfFacebookLinkage,
    path: "/CfFacebookLinkage"
  },
  Notifications: {
    component: Notifications,
    path: "/Notifications"
  },
  CfInstagramLinkage: {
    component: CfInstagramLinkage,
    path: "/CfInstagramLinkage"
  },
  Analytics: {
    component: Analytics,
    path: "/Analytics"
  },
  Filteritems: {
    component: Filteritems,
    path: "/Filteritems"
  },
  Filteroptions: {
    component: Filteroptions,
    path: "/Filteroptions"
  },
  PdfEdit: {
    component: PdfEdit,
    path: "/PdfEdit"
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: "/AdminConsole3"
  },
  DashboardGuests: {
    component: DashboardGuests,
    path: "/DashboardGuests"
  },
  MultilevelApproval: {
    component: MultilevelApproval,
    path: "/MultilevelApproval"
  },
  Settings5: {
    component: Settings5,
    path: "/Settings5"
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: "/UserProfileBasicBlock"
  },
  SentimentAnalysis: {
    component: SentimentAnalysis,
    path: "/SentimentAnalysis"
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: "/Categoriessubcategories"
  },
  ContentManagement: {
    component: ContentManagement,
    path: "/ContentManagement"
  },
  EngagementCalculator: {
    component: EngagementCalculator,
    path: "/EngagementCalculator"
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: "/CountryCodeSelector"
  },
  CfTwitterLinkage: {
    component: CfTwitterLinkage,
    path: "/CfTwitterLinkage"
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: "/PhoneNumberInput"
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: "/AdditionalDetailForm"
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: "/SocialMediaAccountRegistrationScreen"
  },
  Sorting: {
    component: Sorting,
    path: "/Sorting"
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: "/ApiIntegration"
  },
  Contactus: {
    component: Contactus,
    path: "/contactus"
  },
  AddContactus: {
    component: AddContactus,
    path: "/AddContactus"
  },
  Catalogue: {
    component: Catalogue,
    path: "/Catalogue"
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: "/EducationalUserProfile"
  },
  Notificationsettings: {
    component: Notificationsettings,
    path: "/Notificationsettings"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  Dashboard: {
    component: Dashboard,
    path: "/Dashboard"
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: "/EmailAccountLoginBlock"
  },
  CfYoutubeLinkage: {
    component: CfYoutubeLinkage,
    path: "/CfYoutubeLinkage"
  },
  LandingPage: {
    component: LandingPage,
    path: "/LandingPage"
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: "/EmailNotifications"
  },

  Home: {
    component: HomeScreen,
    path: '/abc',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {

  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <View style={{ height: '100vh', width: '100%' }}>
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default withRouter(App);
