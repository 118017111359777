import React from "react";
// Customizable Area Start
import {
    Grid,
    TextField,
    Button,
    InputAdornment
} from "@material-ui/core";
import { logo } from "./assets";
import "../../mobile-account-registration/assets/css/login.css";
// Customizable Area End
import { withRouter } from "react-router-dom";
import MobileAccountLoginController, { Props } from "./MobileAccountLoginController.web";


export class MobileAccountLogin extends MobileAccountLoginController {

    constructor(props: Props) {
        super(props);
        console.log('constructor');
    }

    async componentDidMount():Promise<any> {
        const loginState = localStorage.getItem('loginState');
        if(this.props.location.state) {
            this.setState({mobileNo: this.props.location.state.phone});
            return false;
        }
        else if(loginState){
            this.setState({mobileNo: loginState});
        }
        window.addEventListener("beforeunload", () => {
            this.props.history.replace();
            localStorage.removeItem('loginState');
        });
    }

    render() {
        return (
            // Customizable Area Start
            <div id="Main">
                <div className="Login-Header">
                    <img src={logo} />
                    <Button 
                        id="header-btn"
                        className="btn-secondary btn-outlined" 
                        onClick={() => this.props.history.push('/signup')}
                    >Sign Up</Button>
                </div>
                <Grid container className="logindiv" id="Main">
                    <Grid item xs={12} sm={12} md={6} className="auth-common-sidebar"></Grid>

                    <Grid item xs={12} sm={12} md={6} className="auth-form-container">
                        <h2>Login</h2>
                        <form className="loginform" onSubmit={(e) => this.handleSubmitData(e)}>
                            <span className="labelphone">Phone*</span>
                            <TextField
                                fullWidth
                                id="phone-input"
                                placeholder="Enter Phone No"
                                className="phoneinput"
                                type="number"
                                value={this.state.mobileNo}
                                onChange={(e) => this.setState({ mobileNo: e.target.value})}
                                onInput={(e: any) =>
                                    e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                                }
                                InputProps={{
                                    disableUnderline: true,
                                    name: "mobileNo",
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <span>+91</span>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            {this.state.mobileError && <div className="validation-msg">{this.state.mobileError}</div>}
                            <div className="form-actions">
                                <Button type="submit" className="btn-secondary">Login</Button>
                            </div>
                        </form>
                        <div className="ask-signup">
                            Don't have an account? <span id="bottomLink" onClick={() => this.props.history.push('/signup')}>Sign up</span>
                        </div>
                    </Grid>
                </Grid>
            </div>
            // Customizable Area End
        );
    }
}
// @ts-ignore
export default withRouter(MobileAccountLogin);
